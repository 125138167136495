
.relPos[data-v-5957de7c] {
  position: relative;
}
.absPos[data-v-5957de7c] {
  position: absolute;
}
.loading-text[data-v-5957de7c] {
  display: relative;
}
.centeredOverlay[data-v-5957de7c] {
  background-color: #f4f6ff85;
  /*dim the background*/
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: black;
  text-align: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  display: -ms-flexbox;
  display: flex;
	-ms-flex-direction: column;
	    flex-direction: column;
	z-index: 999;
}
.la-ball-clip-rotate-multiple.la-custom[data-v-5957de7c] {
  color: #9fbaecb0;
}

/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.la-ball-clip-rotate-multiple[data-v-5957de7c],
.la-ball-clip-rotate-multiple > div[data-v-5957de7c] {
  position: relative;
  box-sizing: border-box;
}
.la-ball-clip-rotate-multiple[data-v-5957de7c] {
  display: block;
  font-size: 0;
  color: #fff;
}
.la-ball-clip-rotate-multiple.la-dark[data-v-5957de7c] {
  color: #333;
}
.la-ball-clip-rotate-multiple > div[data-v-5957de7c] {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}
.la-ball-clip-rotate-multiple[data-v-5957de7c] {
  width: 32px;
  height: 32px;
}
.la-ball-clip-rotate-multiple > div[data-v-5957de7c] {
  position: absolute;
  top: 50%;
  left: 50%;
  background: transparent;
  border-style: solid;
  border-width: 2px;
  border-radius: 100%;
  animation: ball-clip-rotate-multiple-rotate-data-v-5957de7c 1s ease-in-out infinite;
}
.la-ball-clip-rotate-multiple > div[data-v-5957de7c]:first-child {
  position: absolute;
  width: 32px;
  height: 32px;
  border-right-color: transparent;
  border-left-color: transparent;
}
.la-ball-clip-rotate-multiple > div[data-v-5957de7c]:last-child {
  width: 16px;
  height: 16px;
  border-top-color: transparent;
  border-bottom-color: transparent;
  animation-duration: 0.5s;
  animation-direction: reverse;
}
.la-ball-clip-rotate-multiple.la-sm[data-v-5957de7c] {
  width: 16px;
  height: 16px;
}
.la-ball-clip-rotate-multiple.la-sm > div[data-v-5957de7c] {
  border-width: 1px;
}
.la-ball-clip-rotate-multiple.la-sm > div[data-v-5957de7c]:first-child {
  width: 16px;
  height: 16px;
}
.la-ball-clip-rotate-multiple.la-sm > div[data-v-5957de7c]:last-child {
  width: 8px;
  height: 8px;
}
.la-ball-clip-rotate-multiple.la-2x[data-v-5957de7c] {
  width: 64px;
  height: 64px;
}
.la-ball-clip-rotate-multiple.la-2x > div[data-v-5957de7c] {
  border-width: 4px;
}
.la-ball-clip-rotate-multiple.la-2x > div[data-v-5957de7c]:first-child {
  width: 64px;
  height: 64px;
}
.la-ball-clip-rotate-multiple.la-2x > div[data-v-5957de7c]:last-child {
  width: 32px;
  height: 32px;
}
.la-ball-clip-rotate-multiple.la-3x[data-v-5957de7c] {
  width: 96px;
  height: 96px;
}
.la-ball-clip-rotate-multiple.la-3x > div[data-v-5957de7c] {
  border-width: 6px;
}
.la-ball-clip-rotate-multiple.la-3x > div[data-v-5957de7c]:first-child {
  width: 96px;
  height: 96px;
}
.la-ball-clip-rotate-multiple.la-3x > div[data-v-5957de7c]:last-child {
  width: 48px;
  height: 48px;
}
/*
 * Animation
 */
@keyframes ball-clip-rotate-multiple-rotate-data-v-5957de7c {
0% {
    transform: translate(-50%, -50%) rotate(0deg);
}
50% {
    transform: translate(-50%, -50%) rotate(180deg);
}
100% {
    transform: translate(-50%, -50%) rotate(360deg);
}
}
