:-ms-input-placeholder {
  color: #737373 !important;
  font-weight: bold !important;
  opacity: 1 !important;
}
::-ms-input-placeholder {
  color: #737373 !important;
  font-weight: bold !important;
  opacity: 1 !important;
}
::placeholder {
  color: #737373 !important;
  font-weight: bold !important;
  opacity: 1 !important;
}
body {
  font-family: "Open Sans";
  font-weight: 300;
  font-size: 14px;
  color: #424242;
}
a {
  color: #2e2e2e;
  cursor: pointer;
}
a:active {
  color: #3991C3;
}
.btn-primary {
  background-color: #3991C3;
}
.help-text {
  display: inline;
  font-size: 12px;
  margin-left: 5px;
}
/* correct input fields */
.has-success .control-label {
  color: #424242;
  font-weight: normal;
}
.has-success .form-control {
  border-color: #ededed;
}
.has-success .input-group-addon {
  color: #424242;
  border-color: rgba(0, 0, 0, 0.07);
  background-color: #fff;
}
.has-success .form-control:focus {
  border-color: #1976d2;
}
.form-errors {
  color: #f55a4e;
  margin-bottom: 10px;
}
.form-errors span {
  display: inherit;
}
.form-info {
  color: #5fd088;
}
/*Custom checkbox*/
.styled-checkbox {
  position: absolute;
  opacity: 0;
}
.styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
  font-weight: 300;
}
.styled-checkbox + label:before {
  content: '';
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  height: 14px;
  background: white;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
}
.styled-checkbox:hover + label:before {
  background: #3991C3;
}
.styled-checkbox:focus + label:before {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12);
}
.styled-checkbox:checked + label:before {
  background: #3991C3;
}
.styled-checkbox:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}
.styled-checkbox:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}
.styled-checkbox:checked + label:after {
  content: '';
  position: absolute;
  left: 3px;
  top: 11px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 3px 0 0 white, 3px -2px 0 white, 3px -4px 0 white, 3px -4px 0 white, 3px -6px 0 white;
  transform: rotate(45deg);
}
#mainnav-container {
  width: 250px;
  z-index: 1000;
}
#navbar {
  z-index: 1001;
}
@media (min-width: 768px) {
  #container.mainnav-lg #footer,
  #container.mainnav-lg #content-container {
    padding-left: 250px;
  }
}
#navbar {
  z-index: 1000;
}
tr.system-category,
tr.k-alt.system-category {
  background-color: rgba(21, 100, 178, 0.15);
}
.desc-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/*******************Filterable select **************************/
.filterable-select {
  position: relative;
}
.filterable-select-results {
  position: absolute;
  padding: 0;
  margin: 0;
  border: 2px solid #eeeeee;
  max-height: 195px;
  overflow: auto;
  box-shadow: 1px 1px #eeeeee;
  width: 100%;
  z-index: 99;
  background-color: white;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}
.filterable-select-result {
  list-style: none;
  text-align: left;
  padding: 8px 0px 8px 10px;
  cursor: pointer;
  background-color: white;
}
.filterable-select-info {
  list-style: none;
  text-align: left;
  padding: 5px 0px 5px 5px;
  background-color: white;
  font-style: italic;
  font-size: small;
}
.filterable-select-result.is-active,
.filterable-select-result:hover {
  background-color: #3991C3;
  color: white;
}
/* Style the input container */
.filterable-select-input {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  border: 1px solid #ececf4;
}
.filterable-select-input i {
  padding: 10px;
  font-size: 12px;
  color: black;
  min-width: 10px;
  text-align: center;
  height: 30px;
}
.filterable-select-input input {
  width: 100%;
  height: 30px;
  outline: none;
  border: none;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 5px;
}
/*******************Filterable select **************************/
.styled-checkbox-column {
  position: absolute;
  opacity: 0;
}
.styled-checkbox-column + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}
.styled-checkbox-column + label:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 14px;
  height: 14px;
  background: white;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
}
.styled-checkbox-column:focus + label:before {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12);
}
.styled-checkbox-column:checked + label:before {
  background: #3991C3;
}
.styled-checkbox-column:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}
.styled-checkbox-column:disabled + label:before {
  box-shadow: none;
  background: #a4a4a4;
}
.styled-checkbox-column:checked + label:after {
  content: '';
  position: absolute;
  left: 3px;
  top: 11px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 3px 0 0 white, 3px -2px 0 white, 3px -4px 0 white, 3px -4px 0 white, 3px -6px 0 white;
  transform: rotate(45deg);
}
/* Kendo grid ***********************************************/
.k-state-selected,
.k-pager-wrap .k-state-selected,
.k-pager-wrap .k-state-selected:hover {
  background-color: #3991C3;
  border-radius: 5px;
  margin: 2px 1px;
}
.k-list .k-item.k-state-selected,
.k-list .k-item.k-state-selected:hover {
  background-color: #3991C3;
}
.k-pager-numbers .k-link,
.k-pager-numbers .k-link:link {
  color: #777;
  border: solid 1px #d9d9d9;
  border-radius: 5px;
  margin: 1px;
}
.k-grid td.k-state-selected,
.k-grid tr.k-state-selected > td {
  color: #656565;
  background-color: rgba(55, 145, 195, 0.2);
}
td a,
.link-in-grid {
  color: #1976d2 !important;
  font-weight: 600;
}
/************************************************************/
/* 5 column *************************************************/
.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xs-15 {
  width: 20%;
  float: left;
}
@media (min-width: 768px) {
  .col-sm-15 {
    width: 20%;
    float: left;
  }
}
@media (min-width: 992px) {
  .col-md-15 {
    width: 20%;
    float: left;
  }
}
@media (min-width: 1200px) {
  .col-lg-15 {
    width: 20%;
    float: left;
  }
}
/************************************************************/
/* Filter panel *********************************************/
#filter-panel {
  border: 1px solid #ccc;
  margin-bottom: 15px;
}
#filter-panel-title {
  background-color: rgba(25, 118, 210, 0.1);
  cursor: pointer;
}
/************************************************************/
#paneled-form {
  background-color: white;
  height: 100%;
  padding: 15px;
}
.form-panel {
  background-color: #f1f7f8;
}
/* Own controls *********************************************/
.g-button {
  background-color: #3991C3;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  padding: 6px 20px;
  text-align: center;
}
.g-button-light {
  border: 1px solid #3991C3;
  border-radius: 10px;
  color: #3991C3;
  cursor: pointer;
  padding: 6px 20px;
  text-align: center;
}
.g-textbox {
  width: 100%;
  border-radius: 25px;
  border: 1px solid #e6e6e6;
  border-top: 0;
  padding: 10px 20px;
  margin: 10px 0;
  outline: none;
}
:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 0.25;
  /* Firefox */
}
::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 0.25;
  /* Firefox */
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 0.25;
  /* Firefox */
}
/************************************************************/
/* Custom radiobutton ***************************************/
.g-radio-button {
  position: absolute;
  opacity: 0;
}
.g-radio-button + label {
  position: relative;
  cursor: pointer;
  padding: 0;
  font-weight: 300;
}
.g-radio-button + label:before {
  content: '';
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
  height: 18px;
  width: 18px;
  background-color: #eee;
  border-radius: 50%;
}
.g-radio-button:hover + label:before {
  background: #3991C3;
}
.g-radio-button:focus + label:before {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12);
}
.g-radio-button:checked + label:before {
  background: #3991C3;
}
.g-radio-button:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}
.g-radio-button:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}
.g-radio-button:checked + label:after {
  content: '';
  position: absolute;
  left: 5px;
  top: 7px;
  background: white;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
/************************************************************/
/************************************************************/
.form-group {
  margin-bottom: 15px !important;
}
.form-group-label {
  color: #4E627B;
  padding-left: 10px;
  font-weight: 600;
}
/* BREADCRUMB */
/*======================================*/
.breadcrumb {
  border-radius: 0;
  background-color: transparent;
  margin-bottom: 0;
  padding-left: 12px !important;
  font-size: 1em;
}
/* Element UI ***********************************************/
.el-select {
  width: 100%;
}
.btn-link {
  color: #1976d2;
}
/*---- Panels ---*/
.panel-info .panel-heading,
.panel-info .panel-footer,
.panel-info.panel-colorful {
  background-color: #1976d2;
  border-color: #1976d2;
}
.panel-bordered-info,
.panel-info.panel-bordered {
  border: 1px solid #1976d2;
}
/************************************************************/
/* enable absolute positioning */
.inner-addon {
  position: relative;
}
/* style glyph */
.inner-addon .glyphicon {
  position: absolute;
  padding: 10px;
  pointer-events: none;
}
/* align glyph */
.left-addon .glyphicon {
  left: 0px;
}
.right-addon .glyphicon {
  right: 0px;
}
/* add padding  */
.left-addon input {
  padding-left: 30px;
}
.right-addon input {
  padding-right: 30px;
}
.time-picker {
  width: 6.1em !important;
  margin: 0px 5px;
}
.time-picker input.display-time {
  border: 1px solid rgba(0, 0, 0, 0.07) !important;
  border-radius: 3px !important;
  box-shadow: none !important;
  height: 33px !important;
  width: 6.1em !important;
}
.time-picker .dropdown ul li.active,
.time-picker .dropdown ul li.active:hover {
  background: #3991c3 !important;
  color: #fff;
}
.center-text {
  text-align: center;
}
.right-text {
  text-align: right;
}
textarea {
  resize: none;
}
.pl-0 {
  padding-left: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.ptb-4 {
  padding: 4px 0;
}
.block {
  display: block;
}
.no-grid-data {
  text-align: center;
  margin: 50px 0;
}
.panel-title-break-word {
  word-break: break-word;
  white-space: normal;
}
.k-treelist .k-grid-header {
  padding-right: 16px !important;
}
.tox-notifications-container {
  display: none;
}
.v-modal {
  z-index: 1000 !important;
}
.el-dialog__wrapper {
  z-index: 1001 !important;
}
.refoundRow {
  color: #1976d2;
}
.dragged-row .k-grid tbody tr {
  cursor: move;
}
.row-placeholder {
  outline-style: dashed;
  outline-width: 1px;
  outline-color: #3991C3;
}
.dragged-row {
  width: 400px;
  padding: 10px;
  background-color: #1976d21a;
}
.register-content .el-switch__label {
  color: #748798 !important;
}
.register-content .el-switch__label.is-active {
  color: #3991C3 !important;
}
.register-content .el-switch__label * {
  font-weight: 600 !important;
}
.contact-page img {
  width: 100%;
}
.contact-page h1 {
  color: #516e90;
}
.contact-page h3 {
  margin-top: 90px;
  color: #1474aa;
}
.contact-page p {
  color: #1474aa;
  font-size: 16px;
}
.about-us-page h1 {
  color: #516e90;
  margin-bottom: 20px;
}
.about-us-page p {
  font-size: 16px;
  text-align: justify;
  margin-bottom: 20px;
}
.grouping {
  margin-bottom: 5px;
}
.grouping .value-row {
  margin-bottom: 0px;
  border-radius: 0px;
}
.grouping .value-row .value-right-top {
  border-left: 0.5px solid;
  border-color: #dfdede;
  border-radius: 0px;
  width: 65%;
  padding-bottom: 7px;
  padding-top: 7px;
  border-top: 0px;
  padding-left: 20px;
  padding-right: 20px;
}
.grouping .value-row .value-right-top .label {
  float: left;
  text-align: left;
  width: 30%;
  padding-left: 0px;
}
.grouping .value-row .value-right {
  border-top: 0.5px solid;
  border-color: #dfdede;
}
.grouping .value-row .value-right .label {
  float: left;
  width: 30%;
  text-align: left;
  padding-left: 0px;
}
.img-company-border {
  display: table;
  width: 100%;
  border: 0.5px solid;
  border-color: #cecece;
  border-radius: 3px;
  margin-bottom: 5px;
}
.img-company-border .value-group {
  display: table-row;
}
.value-row {
  width: 100%;
  background-color: #F8F8F8;
  border-radius: 3px;
  margin-bottom: 5px;
  display: -ms-flexbox;
  display: flex;
}
.value-row .value-left {
  width: 35%;
  padding-bottom: 7px;
  padding-top: 7px;
}
.value-row .label {
  color: #848585;
  padding-left: 20px;
  font-size: 15px;
}
.value-row .value-right {
  border-left: 0.5px solid;
  border-color: #dfdede;
  border-radius: 0px;
  width: 65%;
  padding-bottom: 7px;
  padding-top: 7px;
  padding-left: 20px;
  padding-right: 20px;
}
.value-row .value-right .hr {
  padding-left: -20px;
}
.value-row .value-right .value-right-top {
  all: inherit;
  border-top: 0px;
}
.value-row .value-right span span hr {
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.value-row .group-title {
  width: 100%;
  padding-bottom: 7px;
  padding-top: 7px;
  border-bottom: 0.5px solid;
  border-color: #dfdede;
}
.dashboard-container {
  margin-top: 7px;
  font-family: 'Segoe UI';
  line-height: normal;
  letter-spacing: normal;
}
.dashboard-left {
  border-right: 3px solid white;
  font-weight: bold;
  font-size: 15px;
  padding-bottom: 9px;
  padding-top: 9px;
  color: #848585;
}
.dashboard-card {
  background-color: #F8F8F8;
}
.dashboard-right {
  background-color: #F8F8F8;
  width: 100%;
  text-align: left;
  float: left;
  padding-bottom: 9px;
  padding-top: 9px;
  font-size: 14px;
}
.dashboard-right .button-link {
  width: 33%;
  text-align: center;
  margin-bottom: -2px;
  margin-top: -2px;
  float: right;
  padding: 3px 5px;
  background-color: white;
  border: 1px solid #569ADE;
  color: #569ADE;
  border-radius: 3px;
  font: normal normal 600 12px/16px 'Segoe UI';
}
.dashboard-right .button-link a {
  color: #569ADE;
}
ul.dropdown-menu li {
  cursor: pointer;
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}
ul.dropdown-menu li:hover {
  background: lightgray;
}
.center-verticaly {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-line-pack: center;
      align-content: center;
}
@media (min-width: 768px) {
  .row.equal {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
