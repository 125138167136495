.facet-box[data-v-c71020c6] {
  margin-right: 20px;
  margin-bottom: 20px;
  border: 1px solid #eee;
  border-radius: 10px;
  padding-bottom: 15px;
}
.facet-box h4[data-v-c71020c6] {
  color: #3991c3;
  padding: 20px;
  margin: 0;
}
.apply-filter[data-v-c71020c6] {
  color: #3991C3;
  cursor: pointer;
  margin-bottom: 20px;
}
