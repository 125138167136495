.product-card[data-v-6d456ede] {
  background-color: #fff;
  border: 1px solid #eee;
  margin-bottom: 30px;
  border-radius: 10px;
}
.product-card img[data-v-6d456ede] {
  width: 100%;
  padding: 10px;
}
.product-card .product-category[data-v-6d456ede] {
  margin: 0 0 10px 10px;
}
.product-card .product-name[data-v-6d456ede] {
  margin: 0 0 10px 10px;
  font-weight: bold;
  color: #777;
  text-align: center;
  height: 38px;
  overflow: hidden;
}
.product-card .product-price-info[data-v-6d456ede] {
  margin: 0 0 10px 10px;
  font-weight: bold;
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 0px;
  margin-right: 10px;
}
.product-card .product-price-info .pruduct-price[data-v-6d456ede] {
  padding: 5px;
  display: inline-block;
  color: #3991c3;
}
.product-card .product-price-info .product-more-info[data-v-6d456ede] {
  background-color: #3991c3;
  border-radius: 10px;
  padding: 5px 15px;
  color: #fff;
  float: right;
  cursor: pointer;
}
