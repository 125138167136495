.searchStringDisplay[data-v-53b5d2b0] {
  margin-left: -15px;
}
.clear-search[data-v-53b5d2b0] {
  margin: 0px 5px 5px 0px;
  font-size: 12px;
}
.clear-search[data-v-53b5d2b0]:hover {
  cursor: pointer;
}
.clear-search i[data-v-53b5d2b0] {
  color: #f22314bd;
}
.active-facet[data-v-53b5d2b0] {
  margin: 0px 10px 5px 0px;
  display: inline-block;
  cursor: pointer;
}
.active-facet[data-v-53b5d2b0]:hover {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
}
.active-facet .facet-text[data-v-53b5d2b0] {
  display: table-cell;
  margin-right: 5px;
}
.active-facet i[data-v-53b5d2b0] {
  color: #f22314bd;
  display: table-cell;
}
